<template>
    <v-col cols="12">
        <v-row justify="center">
            <v-col cols="12">
                <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn style="background-color: #FF6600;  color: #fff; width: 248px; height: 48px;" v-bind="attrs"
                            v-on="on">
                            Каталог продукции <v-img src="@/assets/main/chevron-down.svg"
                                style="height: 14px; width: 14px;"></v-img>
                        </v-btn>
                    </template>
                    <v-list v-for="item in items" :key="item.title" class="text-xs-left py-0">
                        <v-list-group v-for="item in items" :key="item.title" no-action sub-group value="true">
                            <template v-slot:activator>
                                <v-list-tile>
                                    <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                                </v-list-tile>
                            </template>
                            <v-list-group v-for="subItem in item.items" :key="subItem.title" no-action sub-group
                                value="true">
                                <template v-slot:activator>
                                    <v-list-tile>
                                        <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
                                    </v-list-tile>
                                </template>

                                <v-list-item v-for="(admin, i) in subItem.items" :key="i" :to="'/farms/'+admin.link">
                                    <v-list-tile-title v-text="admin.title"></v-list-tile-title>
                                    <v-list-tile-action>
                                        <v-icon icon="'mdi-clock'"></v-icon>
                                    </v-list-tile-action>
                                </v-list-item>
                            </v-list-group>
                        </v-list-group>
                    </v-list>
                    <!-- <v-list>
                    <v-list-item v-for="(item, index) in items" :key="index" :to="'/farms/'+item.id" link>
                        <v-list-item-title class="cataloge_style">{{ item.nameObj }}</v-list-item-title>
                    </v-list-item>
                </v-list> -->
                </v-menu>
            </v-col>

        </v-row>

    </v-col>
</template>

<script>
export default {
    name: "test",
    data: () => ({
        items: [
            {
                title: 'Фермы',
                items: [

                    {
                        title: 'СЕРИИ 20',
                        items: [
                            {
                                title: 'D20S',
                                link: '1',
                            },
                             {
                                title: 'Q20S',
                                link: '1',
                            },
                            {
                                title: 'T20S',
                                link: '1',
                            },
                        ]
                    },
                    {
                        title: 'СЕРИИ 30',
                        items: [
                            {
                                title: 'D30S',
                                link: 'D30S',
                            },
                            {
                                title: 'Q30S',
                                link: '4',
                            },
                            {
                                title: 'T30S',
                                link: '5',
                            },
                        ]
                    },
                    {
                        title: 'СЕРИИ 40',
                        items: [
                            {
                                title: 'D40S',
                                link: '6',
                            },
                            {
                                title: 'Q40S',
                                link: '7',
                            },
                            {
                                title: 'T40S',
                                link: '7',
                            },
                        ]
                    },
                    {
                        title: 'СЕРИИ 50',
                        items: [
                            {
                                title: 'Q52S',
                                link: '6',
                            },
                        ]
                    },
                    {
                        title: 'СЕРИИ 100',
                        items: [
                            {
                                title: 'Q100S',
                                link: '6',
                            },
                        ]
                    },
                    {
                        title: 'ПОДИУМЫ',
                        items: [
                            {
                                title: 'LAY',
                                link: '6',
                            },
                             {
                                title: 'ALU',
                                link: '6',
                            },
                        ]
                    },
                ],
                


            },
        ]
    }),
    computed: {
        items() {
            return this.$store.getters.getListMenuMain;
        }
    },
    mounted() {
        this.$store.dispatch("getListMenuSite")
    }
};
</script>  

<style scoped>
.button_site {
    background-color: #FF6600;
    color: #fff;
}

.input_site {

    background-color: #EDF1F6;
}

.dialog_form {
    font-family: IBM Plex Sans;
    font-size: 42px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: 0em;
    text-align: left;

}

.email_form_text {
    font-family: IBM Plex Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;

}
</style>
