<template>
<v-container>
    <v-row >
        
        <v-col cols="12" style="text-align:center">
            <img src="@/assets/catalog/q30s/title.png" alt="logo"  style="width: 100%">
        </v-col>
        
    </v-row>
    <v-row>
        <v-col cols="4" md="4">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center">
                                Артикул
                            </th>
                            <th class="text-center">
                                Длина
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in desserts" :key="item.name">
                            <td>{{ item.name }}</td>
                            <td>{{ item.calories }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
        <v-col cols="8" md="8">
            <img src="@/assets/catalog/q30s/q30s_n1.png" alt="pic" style="width: 100%">
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-simple-table style="width:100%;">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center" rowspan="2">
                                Пролеты
                            </th>
                            <th class="text-center" colspan="2">
                                Равномерно распределенная нагрузка
                            </th>
                            <th class="text-center" colspan="2">
                                Нагрузка на центральную точку
                            </th>
                            <th class="text-center">
                                Нагрузка на 2 точки
                            </th>
                            <th class="text-center">
                                Нагрузка на 3 точки
                            </th>
                            <th class="text-center">
                                Нагрузка на 4 точки
                            </th>
                            <th class="text-center" rowspan="2">

                            </th>
                        </tr>
                        <tr>

                            <th class="text-center" colspan="2">
                                <img src="@/assets/catalog/nagr_all.png" alt="logo"  style="width: 80px">
                            </th>
                            <th class="text-center" colspan="2">
                                <img src="@/assets/catalog/narg_center.png" alt="logo"  style="width: 80px">
                            </th>
                            <th class="text-center">
                                <img src="@/assets/catalog/narg_two.png" alt="logo"  style="width: 80px">
                            </th>
                            <th class="text-center">
                                <img src="@/assets/catalog/narg_tree.png" alt="logo"  style="width: 80px">
                            </th>
                            <th class="text-center">
                                <img src="@/assets/catalog/narg_point.png" alt="logo"  style="width: 80px">
                            </th>

                        </tr>
                        <tr>
                            <th class="text-center">
                                Длинна, м
                            </th>
                            <th class="text-center">
                                кг/м
                            </th>
                            <th class="text-center">
                                Провис, мм
                            </th>
                            <th class="text-center">
                                кг/м
                            </th>
                            <th class="text-center">
                                Провис, мм
                            </th>
                            <th class="text-center">
                                кг/м
                            </th>
                            <th class="text-center">
                                кг/м
                            </th>
                            <th class="text-center">
                                кг/м
                            </th>
                            <th class="text-center">
                                Провис, мм
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in desserts_data" :key="item.name">
                            <td>{{ item.len }}</td>
                            <td>{{ item.n1_kg }}</td>
                            <td>{{ item.n1_pr }}</td>
                            <td>{{ item.n2_kg }}</td>
                            <td>{{ item.n2_pr }}</td>
                            <td>{{ item.n3_kg }}</td>
                            <td>{{ item.n4_kg }}</td>
                            <td>{{ item.n5_kg }}</td>
                            <td>{{ item.n5_pr }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="6">
            <v-simple-table style="text-align:center" class="tb_rr">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center" colspan="5">
                                Консольная нагрузка
                            </th>
                        </tr>
                        <tr>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table_n1.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center"  style="background: #fff">
                                <img src="@/assets/catalog/t30s/table_n2.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table_n3.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table_n4.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table_n3.png" alt="logo" style="width: 80px">
                            </th>
                        </tr>
                        <tr>
                            <th class="text-center">
                                М
                            </th>
                            <th class="text-center">
                                КГ
                            </th>
                            <th class="text-center">
                                ММ
                            </th>
                            <th class="text-center">
                                КГ/М
                            </th>
                            <th class="text-center">
                                ММ
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in desserts_left" :key="item.name">
                            <td>{{ item.n1 }}</td>
                            <td>{{ item.n2 }}</td>
                            <td>{{ item.n3 }}</td>
                            <td>{{ item.n4 }}</td>
                            <td>{{ item.n5 }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
        <v-col cols="6">
             <v-simple-table style="text-align:center" class="tb_rr">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center" colspan="7">
                                 Поддерживаемые диапазоны
                            </th>
                        </tr>
                        <tr>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table2_n1.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table2_n2.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table_n3.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table2_n3.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table_n3.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table2_n4.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table_n3.png" alt="logo" style="width: 80px">
                            </th>
                        </tr>
                        <tr>
                            <th class="text-center">
                                М
                            </th>
                            <th class="text-center">
                                КГ
                            </th>
                            <th class="text-center">
                                ММ
                            </th>
                             <th class="text-center">
                                КГ
                            </th>
                            <th class="text-center">
                                ММ
                            </th>
                            <th class="text-center">
                                КГ/М
                            </th>
                            <th class="text-center">
                                ММ
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in desserts_right" :key="item.name">
                            <td>{{ item.n1 }}</td>
                            <td>{{ item.n2 }}</td>
                            <td>{{ item.n3 }}</td>
                            <td>{{ item.n4 }}</td>
                            <td>{{ item.n5 }}</td>
                            <td>{{ item.n6 }}</td>
                            <td>{{ item.n7 }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="6">
            СЕРТИФИКАТ СООТВЕСТВИЯ
            Регистрационный номер РОСС RU.32001.04ИБФ1.ОСП28.00000
            Расчетный нагрузки прияты в соотвествии с СП 20.13330-2016
            "Нагрузки и воздействия", а материалы в соотвествии с СП
            16.13330.2017 "Стальные конструкции" и СП 128.13330.2016
            "Алюминевые конструкции". и следующих допущениях:
            <ul>
                <li>
                    Только статические нагрузки.

                </li>
            </ul>
        </v-col>
        <v-col cols="6">
            <ul>
                <li>Собранные стропильные системы требуют индивидуального расчета
                    конструкции</li>
                <li>Перед использованием ознакомтесь с руководством</li>
                <li>В зависимости от конфигурации фермы может быть разрешена более
                    высокая нагрузка</li>
                <li>Пролета ферм могут быть исзготовлены из элементов различной длины</li>
            </ul>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: "T30S",
    data: () => ({
        desserts: [

            {
                name: 'Q30S-L021',
                calories: '21 см',
            },
            {
                name: 'Q30S-L025',
                calories: '25 см',
            },
            {
                name: 'Q30S-L029',
                calories: '29 см',
            },
            {
                name: 'Q30S-L050',
                calories: '50 см',
            },
            {
                name: 'Q30S-L071',
                calories: '71 см',
            },
            {
                name: 'Q30S-L075',
                calories: '75 см',
            },
            {
                name: 'Q30S-L100',
                calories: '100 см',
            },
            {
                name: 'T30S-L150',
                calories: '150 см',
            },
            {
                name: 'T30S-L200',
                calories: '200 см',
            },
            {
                name: 'T30S-L250',
                calories: '250 см',
            },
            {
                name: 'T30S-L300',
                calories: '300 см',
            },
            {
                name: 'T30S-L400',
                calories: '400 см',
            },

        ],
        desserts_data: [{
                len: 3,
                n1_kg: 649.0,
                n1_pr: 10,
                n2_kg: 1667.9,
                n2_pr: 8,
                n3_kg: 973.4,
                n4_kg: 649.0,
                n5_kg: 486.7,
                n5_pr: 18.9,

            },
            {
                len: 4,
                n1_kg: 485.3,
                n1_pr: 18,
                n2_kg: 1298.1,
                n2_pr: 15,
                n3_kg: 932.1,
                n4_kg: 647.1,
                n5_kg: 485.3,
                n5_pr: 25.2,

            },
            {
                len: 5,
                n1_kg: 387.1,
                n1_pr: 28,
                n2_kg: 1074.2,
                n2_pr: 23,
                n3_kg: 749.1,
                n4_kg: 581.1,
                n5_kg: 469.0,
                n5_pr: 31.5,

            },
            {
                len: 6,
                n1_kg: 321.6,
                n1_pr: 41,
                n2_kg: 914.4,
                n2_pr: 33,
                n3_kg: 644.7,
                n4_kg: 488.7,
                n5_kg: 389.3,
                n5_pr: 37.8,

            },
            {
                len: 7,
                n1_kg: 255.6,
                n1_pr: 56,
                n2_kg: 794.4,
                n2_pr: 45,
                n3_kg: 564.8,
                n4_kg: 420.7,
                n5_kg: 337.3,
                n5_pr: 44.1,

            },
            {
                len: 8,
                n1_kg: 194.4,
                n1_pr: 73,
                n2_kg: 700.6,
                n2_pr: 58,
                n3_kg: 501.6,
                n4_kg: 368.5,
                n5_kg: 296.9,
                n5_pr: 50.4,

            },
            {
                len: 9,
                n1_kg: 152.4,
                n1_pr: 92,
                n2_kg: 625.2,
                n2_pr: 74,
                n3_kg: 450.2,
                n4_kg: 327.0,
                n5_kg: 264.5,
                n5_pr: 56.7,

            },
            {
                len: 10,
                n1_kg: 122.3,
                n1_pr: 114,
                n2_kg: 563.2,
                n2_pr: 91,
                n3_kg: 407.6,
                n4_kg: 293.2,
                n5_kg: 238.0,
                n5_pr: 63.0,

            },
            {
                len: 11,
                n1_kg: 100.1,
                n1_pr: 137,
                n2_kg: 511.0,
                n2_pr: 110,
                n3_kg: 371.5,
                n4_kg: 265.1,
                n5_kg: 215.7,
                n5_pr: 69.3,

            },
            {
                len: 12,
                n1_kg: 83.2,
                n1_pr: 164,
                n2_kg: 466.5,
                n2_pr: 131,
                n3_kg: 340.6,
                n4_kg: 241.2,
                n5_kg: 196.8,
                n5_pr: 75.6,

            },
            {
                len: 13,
                n1_kg: 70.1,
                n1_pr: 192,
                n2_kg: 428.0,
                n2_pr: 154,
                n3_kg: 313.7,
                n4_kg: 220.7,
                n5_kg: 180.5,
                n5_pr: 81.9,

            },

            {
                len: 14,
                n1_kg: 59.6,
                n1_pr: 223,
                n2_kg: 394.3,
                n2_pr: 178,
                n3_kg: 290.1,
                n4_kg: 202.8,
                n5_kg: 166.2,
                n5_pr: 88.2,

            },
            {
                len: 15,
                n1_kg: 51.2,
                n1_pr: 256,
                n2_kg: 364.5,
                n2_pr: 205,
                n3_kg: 269.1,
                n4_kg: 187.0,
                n5_kg: 153.6,
                n5_pr: 94.5,

            },
            {
                len: 16,
                n1_kg: 44.3,
                n1_pr: 291,
                n2_kg: 337.8,
                n2_pr: 233,
                n3_kg: 250.3,
                n4_kg: 173.0,
                n5_kg: 142.3,
                n5_pr: 100.8,

            },

            {
                len: 17,
                n1_kg: 38.6,
                n1_pr: 328,
                n2_kg: 313.8,
                n2_pr: 263,
                n3_kg: 233.3,
                n4_kg: 160.4,
                n5_kg: 132.2,
                n5_pr: 107.1,

            },
            {
                len: 18,
                n1_kg: 33.8,
                n1_pr: 368,
                n2_kg: 292.0,
                n2_pr: 295,
                n3_kg: 217.9,
                n4_kg: 149.1,
                n5_kg: 123.1,
                n5_pr: 113.4,

            },

            {
                len: 19,
                n1_kg: 29.8,
                n1_pr: 410,
                n2_kg: 272.2,
                n2_pr: 328,
                n3_kg: 203.7,
                n4_kg: 138.7,
                n5_kg: 114.7,
                n5_pr: 119.7,

            },
            {
                len: 20,
                n1_kg: 26.3,
                n1_pr: 454,
                n2_kg: 253.9,
                n2_pr: 364,
                n3_kg: 190.7,
                n4_kg: 129.3,
                n5_kg: 107.1,
                n5_pr: 126.0,

            },
        ],
        desserts_left: [
            {
                n1: 0.5,
                n2: 980.5,
                n3: 0,
                n4: 1958.2,
                n5: 0
            },
            {
                n1: 1.0,
                n2: 979.1,
                n3: 1,
                n4: 976.3,
                n5: 2
            },
            {
                n1: 1.5,
                n2: 839.6,
                n3: 5,
                n4: 649.0,
                n5: 3
            },
            {
                n1: 2.0,
                n2: 652.5,
                n3: 12,
                n4: 485.3,
                n5: 7
            },
            {
                n1: 2.5,
                n2: 539.5,
                n3: 24,
                n4: 387.1,
                n5: 13
            },
            {
                n1: 3.0,
                n2: 458.8,
                n3: 43,
                n4: 276.1,
                n5: 13
            },
            {
                n1: 3.5,
                n2: 398.2,
                n3: 69,
                n4: 204.9,
                n5: 27
            },
            {
                n1: 4.0,
                n2: 351.0,
                n3: 69,
                n4: 159.9,
                n5: 36
            },
        ],
        desserts_right: [
            {
                n1: 2.0,
                n2: 1418.0,
                n3: 1,
                n4: 726.0,
                n5: 1,
                n6: 779.9,
                n7: 0
            },
            {
                n1: 4.0,
                n2: 1407.7,
                n3: 6,
                n4: 715.4,
                n5: 5,
                n6: 387.1,
                n7: 4
            },
            {
                n1: 6.0,
                n2: 1109.9,
                n3: 15,
                n4: 625.5,
                n5: 14,
                n6: 256.2,
                n7: 12
            },
            {
                n1: 8.0,
                n2: 866.3,
                n3: 28,
                n4: 479.4,
                n5: 25,
                n6: 153.7,
                n7: 23
            },
            {
                n1: 10.0,
                n2: 705.5,
                n3: 45,
                n4: 391.5,
                n5: 40,
                n6: 100.7,
                n7: 37
            },
            {
                n1: 12.0,
                n2: 589.6,
                n3: 87,
                n4: 279.2,
                n5: 58,
                n6: 70.6,
                n7: 54
            },
            {
                n1: 14.0,
                n2: 501.4,
                n3: 87,
                n4: 279.2,
                n5: 79,
                n6: 51.8,
                n7: 73
            },
            {
                n1: 16.0,
                n2: 431.5,
                n3: 112,
                n4: 240.6,
                n5: 101,
                n6: 39.2,
                n7: 94
            },
            {
                n1: 20.0,
                n2: 326.3,
                n3: 165,
                n4: 182.2,
                n5: 150,
                n6: 23.9,
                n7: 158
            },

        ],
    }),
    computed: {
        banners() {
            return this.$store.getters.getBannersSite;
        }
    },
    mounted() {
        this.$store.dispatch("getBannersSite");
    }
};
</script>

<style scoped>
.zag {
    text-align: center;
    color: #643d00;
}

.table_s {
    text-align: center;
}

th {
    background-color: #ffc7ae;
    border: #fff 1px solid;
}

td {
    border: #fff 1px solid;
    text-align: center;
}

.tb_id tr {
    background: #fde7de;
}

tr:nth-child(even) {
    background: #fcd7c7;
}

.tb_rr th {
    background: #dfdfdf;
}

.tb_rr tr {
    background: #fff;
}

.tb_rr tr:nth-child(even)  {
    background: #dfdfdf;
}
.fff {
    background: #fff;
}

</style>
