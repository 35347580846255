<template>
<v-container>
    <v-row >
        
        <v-col cols="12" style="text-align:center">
            <img src="@/assets/catalog/d30s/title.png" alt="logo"  style="width: 100%">
        </v-col>
        
    </v-row>
    <v-row>
        <v-col cols="4" md="4">
            <v-simple-table style="text-align:center" class="tb_id">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center">
                                Артикул
                            </th>
                            <th class="text-center">
                                Длина
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in desserts" :key="item.name">
                            <td>{{ item.name }}</td>
                            <td>{{ item.calories }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
        <v-col cols="4" md="4">
            <img src="@/assets/catalog/d30s/d30s_2.png" alt="pic" style="width: 100%">
        </v-col>
        <v-col cols="4" md="4">
            <img src="@/assets/catalog/d30s/d30s_1.png" alt="pic" style="width: 90%">
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" class="zag">
            Допустимая нагрузка (Верхняя труба закреплена с торцов).
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="6">
            <v-simple-table style="width:100%" class="table_s">
                <template v-slot:default>
                    <thead >
                        <tr>
                            <th class="text-center" rowspan="2">
                                Пролеты
                            </th>
                            <th class="text-center" colspan="2">
                                Равномерно распределенная нагрузка<br >
                                <img src="@/assets/catalog/nagr_all.png" alt="logo"  style="width: 80px">
                            </th>
                            <th class="text-center" colspan="2">
                                Нагрузка на центральную точку
                                <br >
                                <img src="@/assets/catalog/narg_center.png" alt="logo"  style="width: 80px">
                            </th>
                           
                                
                            
                        </tr>
                        
                        <tr>
                            <th class="text-center">
                                Длинна, м
                            </th>
                            <th class="text-center">
                                кг/м
                            </th>
                             <th class="text-center">
                                Провис, мм
                            </th>
                            <th class="text-center">
                                кг/м
                            </th>
                            
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in desserts_data" :key="item.name">
                            <td>{{ item.len }}</td>
                            <td>{{ item.n1_kg }}</td>
                            <td>{{ item.n1_pr }}</td>
                            <td>{{ item.n2_kg }}</td>
                            <td>{{ item.n2_pr }}</td>
                           
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
        <v-col cols="6">
            <img src="@/assets/catalog/d30s/d30s_3.png" alt="pic"  style="width: 90%">
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" class="zag">Допустимая нагрузка (Верхняя труба закреплена через каждый метр).</v-col>
    </v-row>
    <v-row>
        <v-col cols="6">
             <v-simple-table style="width:100%">
                <template v-slot:default>
                    <thead >
                        <tr>
                            <th class="text-center" rowspan="2">
                                Пролеты
                            </th>
                            <th class="text-center" colspan="2">
                                Равномерно распределенная нагрузка<br >
                                <img src="@/assets/catalog/nagr_all.png" alt="logo"  style="width: 80px">
                            </th>
                            <th class="text-center" colspan="2">
                                Нагрузка на центральную точку
                                <br >
                                <img src="@/assets/catalog/narg_center.png" alt="logo"  style="width: 80px">
                            </th>
                           
                                
                            
                        </tr>
                        
                        <tr>
                            <th class="text-center">
                                Длинна, м
                            </th>
                            <th class="text-center">
                                кг/м
                            </th>
                             <th class="text-center">
                                Провис, мм
                            </th>
                            <th class="text-center">
                                кг/м
                            </th>
                            
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in desserts_data1" :key="item.name">
                            <td>{{ item.len }}</td>
                            <td>{{ item.n1_kg }}</td>
                            <td>{{ item.n1_pr }}</td>
                            <td>{{ item.n2_kg }}</td>
                            <td>{{ item.n2_pr }}</td>
                           
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
        <v-col cols="6">
            <img src="@/assets/catalog/d30s/d30s_4.png" alt="pic"  style="width: 100%">
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" class="zag">Допустимая нагрузка (Верхняя труба закреплена через каждые 2 метр ).</v-col>
    </v-row>
    <v-row>
        <v-col cols="6">
            <v-simple-table style="width:100%">
                <template v-slot:default>
                    <thead >
                        <tr>
                            <th class="text-center" rowspan="2">
                                Пролеты
                            </th>
                            <th class="text-center" colspan="2">
                                Равномерно распределенная нагрузка<br >
                                <img src="@/assets/catalog/nagr_all.png" alt="logo"  style="width: 80px">
                            </th>
                            <th class="text-center" colspan="2">
                                Нагрузка на центральную точку
                                <br >
                                <img src="@/assets/catalog/narg_center.png" alt="logo"  style="width: 80px">
                            </th>
                           
                                
                            
                        </tr>
                        
                        <tr>
                            <th class="text-center">
                                Длинна, м
                            </th>
                            <th class="text-center">
                                кг/м
                            </th>
                             <th class="text-center">
                                Провис, мм
                            </th>
                            <th class="text-center">
                                кг/м
                            </th>
                            
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in desserts_data2" :key="item.name">
                            <td>{{ item.len }}</td>
                            <td>{{ item.n1_kg }}</td>
                            <td>{{ item.n1_pr }}</td>
                            <td>{{ item.n2_kg }}</td>
                            <td>{{ item.n2_pr }}</td>
                           
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
        <v-col cols="6">
            <img src="@/assets/catalog/d30s/d30s_5.png" alt="pic"  style="width: 100%">
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="6">
            СЕРТИФИКАТ СООТВЕСТВИЯ
            Регистрационный номер РОСС RU.32001.04ИБФ1.ОСП28.00000
            Расчетный нагрузки прияты в соотвествии с СП 20.13330-2016
            "Нагрузки и воздействия", а материалы в соотвествии с СП
            16.13330.2017 "Стальные конструкции" и СП 128.13330.2016
            "Алюминевые конструкции". и следующих допущениях:
            <ul>
                <li>
                    Только статические нагрузки.

                </li>
            </ul>
        </v-col>
        <v-col cols="6">
            <ul>
            <li>Собранные стропильные системы требуют индивидуального расчета
            конструкции</li>
            <li>Перед использованием ознакомтесь с руководством</li>
            <li>В зависимости от конфигурации фермы может быть разрешена более 
            высокая нагрузка</li>
            <li>Пролета ферм могут быть исзготовлены из элементов различной длины</li>
            </ul>
        </v-col>
    </v-row>
     
</v-container>
</template>

<script>
export default {
    name: "D30S",
    data: () => ({
        desserts: [
            {
            name: 'D30S-L025',
            calories: '25 см',
        },
        {
            name: 'D30S-L029',
            calories: '29 см',
        },
        {
            name: 'D30S-L050',
            calories: '50 см',
        },
        {
            name: 'D30S-L071',
            calories: '71 см',
        },
        {
            name: 'D30S-L100',
            calories: '100 см',
        },
        {
            name: 'D30S-L150',
            calories: '150 см',
        },
        {
            name: 'D30S-L200',
            calories: '200 см',
        },
        {
            name: 'D30S-L250',
            calories: '250 см',
        },
        {
            name: 'D30S-L300',
            calories: '300 см',
        },
        {
            name: 'D30S-L400',
            calories: '400 см',
        },
    
    ],
    desserts_data: [
        {
            len: 1,
            n1_kg: 979.2,
            n1_pr: 1,
            n2_kg: 979.2,
            n2_pr: 1,

        },
        {
            len: 2,
            n1_kg: 389,
            n1_pr: 1,
            n2_kg: 389,
            n2_pr: 1,

        },
        {
            len: 3,
            n1_kg: 156,
            n1_pr: 2,
            n2_kg: 234,
            n2_pr: 2,

        },
        {
            len: 4,
            n1_kg: 73,
            n1_pr: 3,
            n2_kg: 146,
            n2_pr: 3,

        },
        {
            len: 5,
            n1_kg: 36,
            n1_pr: 4,
            n2_kg: 90,
            n2_pr: 3,

        },
        {
            len: 6,
            n1_kg: 15,
            n1_pr: 3,
            n2_kg: 45,
            n2_pr: 3,

        },
    ],
    desserts_data1: [
        {
            len: 4,
            n1_kg: 242.8,
            n1_pr: 18,
            n2_kg: 619.9,
            n2_pr: 15,

        },
        {
            len: 5,
            n1_kg: 193.7,
            n1_pr: 28,
            n2_kg: 516.7,
            n2_pr: 23,

        },
        {
            len: 6,
            n1_kg: 161,
            n1_pr: 41,
            n2_kg: 442.2,
            n2_pr: 33,

        },
        {
            len: 7,
            n1_kg: 124.6,
            n1_pr: 56,
            n2_kg: 385.6,
            n2_pr: 45,

        },
        {
            len: 8,
            n1_kg: 95.4,
            n1_pr: 73,
            n2_kg: 341.1,
            n2_pr: 58,

        },
        {
            len: 9,
            n1_kg: 75.1,
            n1_pr: 92,
            n2_kg: 305.2,
            n2_pr: 74,

        },
        {
            len: 10,
            n1_kg: 60.5,
            n1_pr: 114,
            n2_kg: 275.5,
            n2_pr: 91,

        },
        {
            len: 11,
            n1_kg: 49.6,
            n1_pr: 137,
            n2_kg: 205.4,
            n2_pr: 110,

        },
        {
            len: 12,
            n1_kg: 41.4,
            n1_pr: 164,
            n2_kg: 229,
            n2_pr: 131,

        },
    ],
    desserts_data2: [
        {
            len: 4,
            n1_kg: 95.2,
            n1_pr: 4,
            n2_kg: 190.5,
            n2_pr: 4,

        },
        {
            len: 5,
            n1_kg: 60,
            n1_pr: 7,
            n2_kg: 149.9,
            n2_pr: 6,

        },
        {
            len: 6,
            n1_kg: 40.8,
            n1_pr: 10,
            n2_kg: 122.5,
            n2_pr: 8,

        },
        {
            len: 7,
            n1_kg: 29.3,
            n1_pr: 14,
            n2_kg: 102.5,
            n2_pr: 11,

        },
        {
            len: 8,
            n1_kg: 21.8,
            n1_pr: 18,
            n2_kg: 87.1,
            n2_pr: 14,

        },
        {
            len: 9,
            n1_kg: 16.6,
            n1_pr: 23,
            n2_kg: 74.9,
            n2_pr: 18,

        },
        {
            len: 10,
            n1_kg: 13,
            n1_pr: 28,
            n2_kg: 64.8,
            n2_pr: 22,

        },
        {
            len: 11,
            n1_kg: 10.2,
            n1_pr: 34,
            n2_kg: 56.4,
            n2_pr: 27,

        },
        {
            len: 12,
            n1_kg: 8.2,
            n1_pr: 40,
            n2_kg: 49.1,
            n2_pr: 32,

        },
    ],
    }),
    computed: {
        banners() {
            return this.$store.getters.getBannersSite;
        }
    },
    mounted() {
        this.$store.dispatch("getBannersSite");
    }
};
</script>
<style scoped>
#left_title {
    background-image: url("/assets/catalog/left_title.png");
}

.zag {
    text-align: center;
    color: #643d00;
}
.table_s {
    text-align: center;
}
th {
    background-color: #ffc7ae;
    border: #fff 1px solid;
}

td {
    border: #fff 1px solid;
}


.tb_id tr {
    background: #fde7de;
}

tr:nth-child(even) {
  background: #fcd7c7;
}

</style>
