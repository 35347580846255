<template>
<v-container>
    <v-row >
        
        <v-col cols="12" style="text-align:center">
            <img src="@/assets/catalog/t30s/title.png" alt="logo"  style="width: 100%">
        </v-col>
        
    </v-row>
    <v-row>
        <v-col cols="4" md="4">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center">
                                Артикул
                            </th>
                            <th class="text-center">
                                Длина
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in desserts" :key="item.name">
                            <td>{{ item.name }}</td>
                            <td>{{ item.calories }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
        <v-col cols="8" md="8">
            <img src="@/assets/catalog/t30s/t30s_1.png" alt="pic" style="width: 100%">
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-simple-table style="width:100%;">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center" rowspan="2">
                                Пролеты
                            </th>
                            <th class="text-center" colspan="2">
                                Равномерно распределенная нагрузка
                            </th>
                            <th class="text-center" colspan="2">
                                Нагрузка на центральную точку
                            </th>
                            <th class="text-center">
                                Нагрузка на 2 точки
                            </th>
                            <th class="text-center">
                                Нагрузка на 3 точки
                            </th>
                            <th class="text-center">
                                Нагрузка на 4 точки
                            </th>
                            <th class="text-center" rowspan="2">

                            </th>
                        </tr>
                        <tr>

                            <th class="text-center" colspan="2">
                                <img src="@/assets/catalog/nagr_all.png" alt="logo"  style="width: 80px">
                            </th>
                            <th class="text-center" colspan="2">
                                <img src="@/assets/catalog/narg_center.png" alt="logo"  style="width: 80px">
                            </th>
                            <th class="text-center">
                                <img src="@/assets/catalog/narg_two.png" alt="logo"  style="width: 80px">
                            </th>
                            <th class="text-center">
                                <img src="@/assets/catalog/narg_tree.png" alt="logo"  style="width: 80px">
                            </th>
                            <th class="text-center">
                                <img src="@/assets/catalog/narg_point.png" alt="logo"  style="width: 80px">
                            </th>

                        </tr>
                        <tr>
                            <th class="text-center">
                                Длинна, м
                            </th>
                            <th class="text-center">
                                кг/м
                            </th>
                            <th class="text-center">
                                Провис, мм
                            </th>
                            <th class="text-center">
                                кг/м
                            </th>
                            <th class="text-center">
                                Провис, мм
                            </th>
                            <th class="text-center">
                                кг/м
                            </th>
                            <th class="text-center">
                                кг/м
                            </th>
                            <th class="text-center">
                                кг/м
                            </th>
                            <th class="text-center">
                                Провис, мм
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in desserts_data" :key="item.name">
                            <td>{{ item.len }}</td>
                            <td>{{ item.n1_kg }}</td>
                            <td>{{ item.n1_pr }}</td>
                            <td>{{ item.n2_kg }}</td>
                            <td>{{ item.n2_pr }}</td>
                            <td>{{ item.n3_kg }}</td>
                            <td>{{ item.n4_kg }}</td>
                            <td>{{ item.n5_kg }}</td>
                            <td>{{ item.n5_pr }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="6">
            <v-simple-table style="text-align:center" class="tb_rr">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center" colspan="5">
                                Консольная нагрузка
                            </th>
                        </tr>
                        <tr>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table_n1.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center"  style="background: #fff">
                                <img src="@/assets/catalog/t30s/table_n2.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table_n3.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table_n4.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table_n3.png" alt="logo" style="width: 80px">
                            </th>
                        </tr>
                        <tr>
                            <th class="text-center">
                                М
                            </th>
                            <th class="text-center">
                                КГ
                            </th>
                            <th class="text-center">
                                ММ
                            </th>
                            <th class="text-center">
                                КГ/М
                            </th>
                            <th class="text-center">
                                ММ
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in desserts_left" :key="item.name">
                            <td>{{ item.n1 }}</td>
                            <td>{{ item.n2 }}</td>
                            <td>{{ item.n3 }}</td>
                            <td>{{ item.n4 }}</td>
                            <td>{{ item.n5 }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
        <v-col cols="6">
             <v-simple-table style="text-align:center" class="tb_rr">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center" colspan="7">
                                 Поддерживаемые диапазоны
                            </th>
                        </tr>
                        <tr>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table2_n1.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table2_n2.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table_n3.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table2_n3.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table_n3.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table2_n4.png" alt="logo" style="width: 80px">
                            </th>
                            <th class="text-center" style="background: #fff">
                                <img src="@/assets/catalog/t30s/table_n3.png" alt="logo" style="width: 80px">
                            </th>
                        </tr>
                        <tr>
                            <th class="text-center">
                                М
                            </th>
                            <th class="text-center">
                                КГ
                            </th>
                            <th class="text-center">
                                ММ
                            </th>
                             <th class="text-center">
                                КГ
                            </th>
                            <th class="text-center">
                                ММ
                            </th>
                            <th class="text-center">
                                КГ/М
                            </th>
                            <th class="text-center">
                                ММ
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in desserts_right" :key="item.name">
                            <td>{{ item.n1 }}</td>
                            <td>{{ item.n2 }}</td>
                            <td>{{ item.n3 }}</td>
                            <td>{{ item.n4 }}</td>
                            <td>{{ item.n5 }}</td>
                            <td>{{ item.n6 }}</td>
                            <td>{{ item.n7 }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="6">
            СЕРТИФИКАТ СООТВЕСТВИЯ
            Регистрационный номер РОСС RU.32001.04ИБФ1.ОСП28.00000
            Расчетный нагрузки прияты в соотвествии с СП 20.13330-2016
            "Нагрузки и воздействия", а материалы в соотвествии с СП
            16.13330.2017 "Стальные конструкции" и СП 128.13330.2016
            "Алюминевые конструкции". и следующих допущениях:
            <ul>
                <li>
                    Только статические нагрузки.

                </li>
            </ul>
        </v-col>
        <v-col cols="6">
            <ul>
                <li>Собранные стропильные системы требуют индивидуального расчета
                    конструкции</li>
                <li>Перед использованием ознакомтесь с руководством</li>
                <li>В зависимости от конфигурации фермы может быть разрешена более
                    высокая нагрузка</li>
                <li>Пролета ферм могут быть исзготовлены из элементов различной длины</li>
            </ul>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: "T30S",
    data: () => ({
        desserts: [{
                name: 'T30S-L025',
                calories: '25 см',
            },
            {
                name: 'T30S-L029',
                calories: '29 см',
            },
            {
                name: 'T30S-L050',
                calories: '50 см',
            },
            {
                name: 'T30S-L071',
                calories: '71 см',
            },
            {
                name: 'T30S-L100',
                calories: '100 см',
            },
            {
                name: 'T30S-L150',
                calories: '150 см',
            },
            {
                name: 'T30S-L200',
                calories: '200 см',
            },
            {
                name: 'T30S-L250',
                calories: '250 см',
            },
            {
                name: 'T30S-L300',
                calories: '300 см',
            },
            {
                name: 'T30S-L400',
                calories: '400 см',
            },

        ],
        desserts_data: [{
                len: 3,
                n1_kg: 562.4,
                n1_pr: 13,
                n2_kg: 782.3,
                n2_pr: 11,
                n3_kg: 546.6,
                n4_kg: 422.2,
                n5_kg: 334.1,
                n5_pr: 15.0,

            },
            {
                len: 4,
                n1_kg: 342,
                n1_pr: 23,
                n2_kg: 606,
                n2_pr: 19,
                n3_kg: 430.3,
                n4_kg: 321.3,
                n5_kg: 257.4,
                n5_pr: 20.0,

            },
            {
                len: 5,
                n1_kg: 217.3,
                n1_pr: 37,
                n2_kg: 492.8,
                n2_pr: 29,
                n3_kg: 352.7,
                n4_kg: 258.4,
                n5_kg: 208.6,
                n5_pr: 25.0,

            },
            {
                len: 6,
                n1_kg: 149.5,
                n1_pr: 53,
                n2_kg: 413.6,
                n2_pr: 42,
                n3_kg: 299.3,
                n4_kg: 215.2,
                n5_kg: 174.7,
                n5_pr: 30.0,

            },
            {
                len: 7,
                n1_kg: 108.6,
                n1_pr: 72,
                n2_kg: 354.9,
                n2_pr: 57,
                n3_kg: 258.4,
                n4_kg: 183.6,
                n5_kg: 149.6,
                n5_pr: 35.0,

            },
            {
                len: 8,
                n1_kg: 82.1,
                n1_pr: 94,
                n2_kg: 309.4,
                n2_pr: 75,
                n3_kg: 226.6,
                n4_kg: 159.3,
                n5_kg: 130.3,
                n5_pr: 40.0,

            },
            {
                len: 9,
                n1_kg: 63.9,
                n1_pr: 118,
                n2_kg: 273,
                n2_pr: 95,
                n3_kg: 200.9,
                n4_kg: 140.1,
                n5_kg: 114.9,
                n5_pr: 45.0,

            },
            {
                len: 10,
                n1_kg: 50.9,
                n1_pr: 146,
                n2_kg: 243.1,
                n2_pr: 117,
                n3_kg: 179.7,
                n4_kg: 124.4,
                n5_kg: 102.3,
                n5_pr: 50.0,

            },
            {
                len: 11,
                n1_kg: 41.3,
                n1_pr: 117,
                n2_kg: 217.9,
                n2_pr: 142,
                n3_kg: 161.8,
                n4_kg: 111.2,
                n5_kg: 91.7,
                n5_pr: 55.0,

            },
            {
                len: 12,
                n1_kg: 34,
                n1_pr: 211,
                n2_kg: 196.5,
                n2_pr: 169,
                n3_kg: 146.5,
                n4_kg: 100.1,
                n5_kg: 82.7,
                n5_pr: 60.0,

            },
            {
                len: 13,
                n1_kg: 28.3,
                n1_pr: 247,
                n2_kg: 177.8,
                n2_pr: 198,
                n3_kg: 133.2,
                n4_kg: 90.4,
                n5_kg: 74.9,
                n5_pr: 65.0,

            },

            {
                len: 14,
                n1_kg: 23.8,
                n1_pr: 287,
                n2_kg: 161.4,
                n2_pr: 229,
                n3_kg: 121.5,
                n4_kg: 82,
                n5_kg: 68,
                n5_pr: 70.0,

            },
            {
                len: 15,
                n1_kg: 20.1,
                n1_pr: 329,
                n2_kg: 149.9,
                n2_pr: 263,
                n3_kg: 111,
                n4_kg: 74.5,
                n5_kg: 61.9,
                n5_pr: 75.0,

            },
            {
                len: 16,
                n1_kg: 17.2,
                n1_pr: 375,
                n2_kg: 133.8,
                n2_pr: 300,
                n3_kg: 101.6,
                n4_kg: 67.7,
                n5_kg: 56.4,
                n5_pr: 60.8,

            },
        ],
        desserts_left: [
            {
                n1: 0.5,
                n2: 708.0,
                n3: 0,
                n4: 1697.3,
                n5: 0
            },
            {
                n1: 1.0,
                n2: 406.0,
                n3: 1,
                n4: 706.1,
                n5: 2
            },
            {
                n1: 1.5,
                n2: 287.6,
                n3: 5,
                n4: 338.2,
                n5: 4
            },
            {
                n1: 2.0,
                n2: 221.8,
                n3: 11,
                n4: 201.0,
                n5: 8
            },
            {
                n1: 2.5,
                n2: 179.8,
                n3: 22,
                n4: 132.7,
                n5: 12
            },
            {
                n1: 3.0,
                n2: 150.5,
                n3: 39,
                n4: 93.7,
                n5: 18
            },
            {
                n1: 3.5,
                n2: 128.8,
                n3: 62,
                n4: 69.4,
                n5: 25
            },
            {
                n1: 4.0,
                n2: 112.0,
                n3: 92,
                n4: 53.1,
                n5: 34
            },
        ],
        desserts_right: [
            {
                n1: 2.0,
                n2: 953.3,
                n3: 1,
                n4: 530.5,
                n5: 1,
                n6: 676.9,
                n7: 1
            },
            {
                n1: 4.0,
                n2: 545.4,
                n3: 6,
                n4: 301.6,
                n5: 5,
                n6: 191.9,
                n7: 5
            },
            {
                n1: 6.0,
                n2: 379.6,
                n3: 14,
                n4: 210.9,
                n5: 13,
                n6: 90.7,
                n7: 12
            },
            {
                n1: 8.0,
                n2: 286.5,
                n3: 25,
                n4: 259.6,
                n5: 23,
                n6: 51.9,
                n7: 21
            },
            {
                n1: 10.0,
                n2: 226.1,
                n3: 39,
                n4: 126.2,
                n5: 36,
                n6: 33.0,
                n7: 33
            },
            {
                n1: 12.0,
                n2: 183.0,
                n3: 55,
                n4: 120.3,
                n5: 50,
                n6: 22.3,
                n7: 47
            },
            {
                n1: 14.0,
                n2: 150.3,
                n3: 71,
                n4: 84.1,
                n5: 65,
                n6: 15.8,
                n7: 61
            },
            {
                n1: 16.0,
                n2: 124.3,
                n3: 88,
                n4: 69.6,
                n5: 80,
                n6: 11.5,
                n7: 75
            },
            {
                n1: 20.0,
                n2: 84.7,
                n3: 117,
                n4: 47.4,
                n5: 107,
                n6: 6.3,
                n7: 117
            },

        ],
    }),
    computed: {
        banners() {
            return this.$store.getters.getBannersSite;
        }
    },
    mounted() {
        this.$store.dispatch("getBannersSite");
    }
};
</script>

<style scoped>
.zag {
    text-align: center;
    color: #643d00;
}

.table_s {
    text-align: center;
}

th {
    background-color: #ffc7ae;
    border: #fff 1px solid;
}

td {
    border: #fff 1px solid;
    text-align: center;
}

.tb_id tr {
    background: #fde7de;
}

tr:nth-child(even) {
    background: #fcd7c7;
}

.tb_rr th {
    background: #dfdfdf;
}

.tb_rr tr {
    background: #fff;
}

.tb_rr tr:nth-child(even)  {
    background: #dfdfdf;
}
.fff {
    background: #fff;
}

</style>
