<template>
 <v-row style="margin: auto; max-width: 1420px;">
     <v-col cols="12" md=12>
         <v-row>
             <v-col cols="12" md="9" style="max-width: 1040px;">
                 <!-- <v-img src="@/assets/main/rightConstr.svg" style="width: 488px; height: 726px; float: left;"></v-img>
            
                 <v-img src="@/assets/main/leftConstr.svg" style="width: 452px; height: 726px;"></v-img> -->
                 <v-img src="@/assets/main/share.png" ></v-img>
             </v-col>
             <v-col cols="12" md="3" class="innov_text" style="background-color: #fff;">
                <v-row>
                    <v-col cols="12">
                        <p style="font-family: IBM Plex Sans;font-size: 42px;font-weight: 700;line-height: 50px;letter-spacing: 0em;text-align: left;">Инновационные решения</p>
                    </v-col>
                    <v-col cols="12">
                        <p style="font-family: IBM Plex Sans;font-size: 18px;font-weight: 400;line-height: 25px;letter-spacing: 0em;text-align: left;">Компания «Truss for Stage» является ведущим производителем профессиональных сценических систем и конструкций и предлагаем следующую продукцию: сценические системы, подиумы, эстрадные площадки</p>
                    </v-col>
                </v-row>
             </v-col>
         </v-row>
        
     </v-col>
 </v-row>
</template>

 
<script>
 export default {
     name: "test",
     data: () => ({}),
     computed: {},
     mounted() {}
 };
</script>  
<style scoped>
 .button_site {
     background-color: #FF6600;
     color: #fff;
 }

 .innov_text {
        margin-top: 350px;
    } 

 @media screen and (max-width: 450px) {
    .innov_text {
        margin-top: 0px;
        width: 100%;
        word-wrap: break-word;
    }
    
 }
</style>
