<template>
<v-col cols="12">
    <v-row justify="center">

        <v-dialog v-model="dialog" max-width="700">
            <v-card>
                <v-card-title class="dialog_form">
                    Оставьте заявку на документ спецификации
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <p class="email_form_text">Вы получите документ на E-mail</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field class="input_site" label="Ваше имя*" rounded></v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-text-field class="input_site" label="Ваш E-mail*" rounded></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn style="background-color: #FF6600; color: #fff; font-family: IBM Plex Sans; font-size: 14px; font-weight: 500; line-height: 22px; letter-spacing: 0em; text-align: left; " @click="dialog = false">
                        Оставить заявку
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>

    <v-row>
        <v-col cols="12">
            <v-btn style="background-color: #FF6600; color: #fff; font-family: IBM Plex Sans; font-size: 14px; font-weight: 500; line-height: 22px; letter-spacing: 0em; text-align: left; width: 233px; height: 70px;" @click.stop="dialog=true">Оставить заявку</v-btn>
        </v-col>
    </v-row>
    
   
</v-col>
</template>

   
    
   
<script>
export default {
    name: "test",
    data: () => ({
        dialog: false,
    }),
    computed: {},
    mounted() {}
};
</script>  
   
<style scoped>
.button_site {
    background-color: #FF6600;
    color: #fff;
}

.input_site {

    background-color: #EDF1F6;
}

.dialog_form {
    font-family: IBM Plex Sans;
    font-size: 42px;
    font-weight: 700;
    line-height: 55px;
    letter-spacing: 0em;
    text-align: left;

}

.email_form_text {
    font-family: IBM Plex Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;

}
</style>
